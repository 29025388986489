<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">

        <div class="va-card">
          <div class="row">

            <div class="col-lg-12">
              <div class="dash_card_body">

                <div class="header_section">
                  <h4 class="menu_header">Faqs</h4>
                  <div class="">
                    <button class="btn btn-default custom_btn custon_orange" data-target="#faq_modal" data-toggle="modal">
                      <i class="fa fa-plus"></i> New Faqs
                    </button>
                  </div>
                </div>

                <!-- modal for confirmation -->
                <div class="modal fade" id="faq_modal" role="dialog">
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title">Create Faq</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <!-- <h6>Are you sure you want to update menu on all third party vendors?</h6> -->
                        <div class="channel_select">
                          <div class="form-group">
                            <label>Question:</label>
                            <div>
                              <editor v-model="question" :init="{
                                height: 200,
                                branding: false
                              }" />
                            </div>
                           
                          </div>
                        </div>
                        <div class="branch_area">

                          <div class="form-group">
                            <label>Answer:</label>
                            <div>
                              <editor v-model="answer" :init="{
                                height: 200,
                                branding: false
                              }" />
                            </div>
                          
                          </div>

                        </div>
                        <div  class="form-group">
                          <label>Status:</label>
                          <select class="form-control mt-1" v-model="status">
                            <option value="1">Published</option>
                            <option value="2">Unpublished</option>
                          </select>
                        </div>

                        <div  class="form-group">
                          <label>Type:</label>
                          <select class="form-control mt-1" v-model="faqType">
                            <option value="giftcard">Giftcard</option>
                            <option value="order">Order</option>
                          </select>
                        </div>
                      </div> 
                      <div class="modal-footer">
                        <button type="button" id="close_faq" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-orange" @click.prevent = "createFaqs()"> <span v-if="creatingFaqs"><span v-html="spinner"></span></span>Save</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="search_section">
                  <div class="row">
                    <div class="col-md-5">
                      <select class="form-control mt-1" @change="searchfaqs()" v-model="searchStatus">
                        <option value="">--Select status --</option>
                        <option value="1">Published</option>
                        <option value="2">Unpublished</option>
                      </select>
                    </div>

                  </div>
                </div>
              </div>
              <hr>
            </div>

            <!-- faq Modal -->
           
            <div class="col-lg-12">
              <!--Filters-->
              <div class="filters" id="filter_bar" style="display:none">
                <div class="" v-if="user.role.id == 1 || user.role.id == 2">
                  <span class="btn-filter mt-1">
                    <input type="checkbox" id="uncheck" @click="checkItems('uncheck')"
                      indeterminate=true>
                  </span>
                  <button class="btn-filter mr-1 mt-1">
                    {{ faqSelected > 1 ? faqSelected + ' faqs' : faqSelected + ' faq' }} selected
                  </button>
                  <button class="btn-filter mr-2 mt-1" @click="bulkStatusUpdate('publish', 1)">
                    <i class='fa fa-check' style="color:green;"></i>
                    <span v-if="methodSpinner == 'publish'"><span v-html="spinner"></span> </span> <span
                      v-else>
                      Publish</span>
                  </button>
                  <button class="btn-filter mr-2 mt-1" @click="bulkStatusUpdate('unpublish', 2)">
                    <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                    <span v-if="methodSpinner == 'unpublish'"><span v-html="spinner"></span></span>
                    <span v-else>
                      Unpublish</span> </button>
                </div>
              </div>

              <div class="dash_card_body pt-0">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th width="5px">
                          <input type="checkbox" id="check_all" @click="checkItems('check')">
                        </th>
                        <th scope="col">Question</th>
                        <th scope="col">Answer</th>
                        <th scope="col">Status</th>
                        <th scope="col"> Type</th>
                        <th scope="col">Last Updated</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="faq in faqs" :key="faq.id" draggable="true"
                        @drop="onDrop($event, faq)" @dragenter.prevent @dragover.prevent
                        @dragstart="startDrag($event, faq)">
                        <td>
                          <input type="checkbox" :id="'faq_' + faq.id"
                            @click="selectFaqs(faq.id)">
                        </td>
                        <td>
                          <span class="title_text" v-html="faq.question"> </span>
                        </td>
                        <td>
                          <span v-html="faq.answer"></span>
                        </td>
                        <!-- status -->
                        <td>
                          <div class="btn-group">
                            <button class="btn custom_btn btn-sm dropdown-toggle'"
                              type="button">
                              <span v-if="faq.status == 1">
                                <i class='fa fa-check' style="color:green;"></i>
                              </span>
                              <span v-else-if="faq.status == 2">
                                <i class="fa fa-close" style="color:red;"
                                  aria-hidden="true"></i>
                              </span>
                              <span v-else-if="faq.status == 3">
                                <i class="fa fa-archive" aria-hidden="true"></i>
                              </span>
                              <span v-else>
                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                              </span>
                            </button>
                            <button v-if="user.role.id == 1 || user.role.id == 2" type="button"
                              class="btn btn-sm custom_btn btn-default dropdown-toggle"
                              data-toggle="dropdown" :id="'dropdownMenuButton' + faq.id">
                              <span class="caret"></span>
                              <span class="sr-only">Toggle Dropdown</span>
                            </button>
                            <div class="dropdown-menu"
                              :aria-labelledby="'dropdownMenuButton' + faq.id">
                              <a class="dropdown-item" @click="updateStatsPerItem(faq.id, 1)"
                                href="#">Publish</a>
                              <a class="dropdown-item" @click="updateStatsPerItem(faq.id, 2)"
                                href="#">Unpublish</a>
                            </div>
                          </div>
                        </td>
                        <td>
                          {{ faq.faq_type }}
                        </td>
                        <td>
                          <span v-if="faq.updated_at !== null">{{ formatDate(faq.updated_at)
                          }}</span>
                          <span v-else>{{ formatDate(faq.created_at) }}</span>
                        </td>
                        <td>
                          <button class="btn btn-purple btn-sm mr-2" data-toggle="modal" :data-target="`#faq_modal_${faq.id}`" @click="editFaq(faq)"><i class='fas fa-edit'></i></button>
                        </td>

                        <!-- edit modal -->

                        <div class="modal fade" :id="`faq_modal_${faq.id}`" role="dialog">
                          <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title">Update Faq</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <!-- <h6>Are you sure you want to update menu on all third party vendors?</h6> -->
                                <div class="channel_select">
                                  <div class="form-group">
                                    <label>Question:</label>
                                    <div>
                                      <editor v-model="question" :init="{
                                        height: 200,
                                        branding: false
                                      }" />
                                    </div>
                           
                                  </div>
                                </div>
                                <div class="branch_area">

                                  <div class="form-group">
                                    <label>Answer:</label>
                                    <div>
                                      <editor v-model="answer" :init="{
                                        height: 200,
                                        branding: false
                                      }" />
                                    </div>
                          
                                  </div>

                                </div>
                                <div  class="form-group">
                                  <label>Status:</label>
                                  <select class="form-control mt-1" v-model="status">
                                    <option value="1">Published</option>
                                    <option value="2">Unpublished</option>
                                  </select>
                                </div>

                                <div  class="form-group">
                                  <label>Type:</label>
                                  <select class="form-control mt-1" v-model="faqType">
                                    <option value="giftcard">Giftcard</option>
                                    <option value="order">Order</option>
                                  </select>
                                </div>
                              </div> 
                              <div class="modal-footer">
                                <button type="button" :id="`close_faq_${faq.id}`" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                                <button type="button" class="btn btn-orange" @click.prevent = "updateFaqs(faq.id)"> <span v-if="creatingFaqs"><span v-html="spinner"></span></span>Save</button>
                              </div>
                            </div>
                          </div>
                        </div>



                      </tr>
                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="8">
                          <div id="pagination" v-if="totalPages > 1">
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage"
                                  v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                </select>
                              </li>

                            </ul>
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage"
                              :current-page="page" @pagechanged="onPageChange" />

                          </div>

                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <div v-if="loading" class="loading-cover">
                <div class="loader"> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
  
<script>
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins'
import Pagination from '@/pages/pagination/pagination.vue';
import { useToast } from "vue-toastification";
const toast = useToast();

import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import Editor from '@tinymce/tinymce-vue';

export default {
    components: {
        Pagination,
        Editor
    },
    name: 'faq-page',
    data() {
        return {
            faqSelected: 0,
            faqs: [],
            loading: false,
            catTooltip: 0,
            page: 1,
            totalPages: 10,
            totalRecords: 0,
            recordsPerPage: 10,
            methodSpinner: null,
            spinner: "<i class='fa fa-spinner fa-spin '></i>",
            faqIds: [],
            status: 1,
            searchStatus:'',
            debounce: null,
            creatingFaqs:false,
            search: '',
            question:'',
            answer:'',
            faqType: 'giftcard',

        }
    },
    created: async function () {
        this.getFaqs();
    },
    methods: {
        editFaq(faq){
            this.question = faq.question
            this.answer = faq.answer
            this.faqType = faq.faq_type
            this.status = faq.status
        },
        startDrag(event, faq) {     
          event.dataTransfer.dropEffect = 'move'      
          event.dataTransfer.effectAllowed = 'move' 
          event.dataTransfer.setData('from_pos',faq.position);
          event.dataTransfer.setData('from_id',faq.id);
        },
        async onDrop(event,faq){
          const frompos = event.dataTransfer.getData('from_pos')
          const fromMenuId = event.dataTransfer.getData('from_id')
          if(this.categorySearch !=''){
            try{
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              const url = `${this.dashBaseUrl}/faq-postion/${this.merchantID}`;
              const payload = {
                'from': fromMenuId,// parseInt(frompos),
                'postion_from': parseInt(frompos),
                'to': faq.id,
                'postion_to':faq.position
              }
              const response = await this.axios.post(url,payload, config);
              if(response.status ==200){
                this.getFaqs();
              }

            }catch(error){
              console.log('error')
            }
          }
        },
        searchfaqs() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getFaqs();
            }, 600)
        },
        checkItems(type) {
            document.querySelector('#uncheck').indeterminate = true;
            if (type === 'check') {
                if (document.getElementById('check_all').checked) {
                    document.getElementById('filter_bar').style.display = 'block'
                    this.faqSelected = 0;
                    for (const faq of Object.values(this.faqs)) {
                        this.faqSelected += 1;
                        document.getElementById('faq_' + faq.id).checked = true
                        if (this.faqIds.includes(faq.id) === false) {
                            this.faqIds.push(faq.id);
                        }
                    }
                } else {
                    document.getElementById('filter_bar').style.display = 'none'
                    for (const faq of Object.values(this.faqs)) {
                        document.getElementById('faq_' + faq.id).checked = false
                    }
                    this.faqIds = []
                }

            } else {
                document.getElementById('filter_bar').style.display = 'none'
                document.getElementById('check_all').checked = false
                for (const faq of Object.values(this.faqs)) {
                    document.getElementById('faq_' + faq.id).checked = false
                }
                this.faqIds = []
            }

        },
        selectFaqs(faqId) {
            if (document.getElementById('faq_' + faqId).checked) {
                if (this.faqIds.includes(faqId) === false) {
                    this.faqIds.push(faqId);
                }
                document.getElementById('filter_bar').style.display = 'block'
            } else {
                if (this.faqIds.indexOf(faqId) !== -1) {
                    this.faqIds = this.faqIds.filter((x) => x !== faqId);
                }
                for (const faq of Object.values(this.faqs)) {
                    if (faq.id != faqId && document.getElementById('faq_' + faq.id).checked) {
                        document.getElementById('filter_bar').style.display = 'block'
                        break;
                    } else {
                        document.getElementById('filter_bar').style.display = 'none'
                    }
                }
            }

            this.faqSelected = 0;
            for (const faq of Object.values(this.faqs)) {
                if (document.getElementById('faq_' + faq.id).checked) {
                    this.faqSelected += 1;
                }
            }
        },
        bulkStatusUpdate(method, status) {
            this.updateStatus(this.faqIds, method, status);
        },
        updateStatsPerItem(faqId, status) {
            const faqIdsArr = [];
            if (faqIdsArr.includes(faqId) === false) {
                faqIdsArr.push(faqId)
            }
            this.updateStatus(faqIdsArr, '', status)
        },
        updateStatus(faqarr, method, status) {
            if (faqarr.length > 0) {
                this.methodSpinner = method;
                if (method === 'publish' && status === false) {
                    this.methodSpinner = 'unpublish'
                }
                const payload = {
                    "ids": faqarr,
                    "status": status
                };
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                const url = `${this.dashBaseUrl}/update-faq-status/${this.merchantID}`;
                this.axios.post(url, payload, config).then((response) => {
                    this.faqIds = [];
                    if (response.status === 200) {
                        toast.success('updated successfully')
                        this.methodSpinner = null;
                        this.getFaqs();
                        // 
                    }
                }).catch((error) => {
                    this.methodSpinner = null;
                    if (error.response.status === 401) {
                        this.$router.push({
                            name: 'login'
                        });
                    }
                });
            }
        },
        async getFaqs() {
            this.loading = true;
            // const url = `${this.baseUrl}//faq?page=${this.page}&size=${this.recordsPerPage}&merchant_id=${this.merchantID}&query=${this.search}`;
            let url = `${this.dashBaseUrl}/list-faqs/${this.merchantID}`;
            url = new URL(url);
            url.searchParams.set('page', this.page);
            url.searchParams.set('size', this.recordsPerPage);
            url.searchParams.set('status', this.searchStatus);

            this.axios.get(url, this.apiRequestConfig()).then((response) => {
                if (response.status === 200) {
                    console.log("reponse",response.data.data.data)
                    this.faqs = response.data.data.data;
                    this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage);
                    this.totalRecords = response.data.data.total_count;
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            }).finally(() => this.loading = false);
        },

        async createFaqs(){
            this.creatingFaqs = true 
            try {
                const url = `${this.dashBaseUrl}/create-faqs/${this.merchantID}`;
                const payload = {
                    'question' : this.question,
                    'answer' : this.answer,
                    'faq_type':this.faqType,
                    'status':this.status,
                }
                const response = await this.axios.post(url,payload,this.apiRequestConfig())
                if(response.status == 201){
                    this.creatingFaqs =  false
                    toast.success('Faq created successfully')
                    this.getFaqs()
                    document.getElementById('close_faq').click();
                }
                
            } catch (error) {
                document.getElementById('close_faq').click();
                this.creatingFaqs = false
                if (error.response && error.response.status === 400) { 
                    let errormsgs = '';
                    for (let x = 0; x < error.response.data.errors.length; x++) {
                        errormsgs = error.response.data.errors[x] + ','
                    }
                    toast.error(errormsgs);
                }else if(error.response && error.response.status === 401){
                  toast.error('session has expired');
                  this.$router.push({
                          name: 'login'
                      });
                } 
            }
            
        },
        async updateFaqs(id){
            this.creatingFaqs = true 
            try {
                const url = `${this.dashBaseUrl}/update-faqs/${id}/${this.merchantID}`;
                const payload = {
                    'question' : this.question,
                    'answer' : this.answer,
                    'faq_type':this.faqType,
                    'status':this.status,
                }
                const response = await this.axios.post(url,payload,this.apiRequestConfig())
                if(response.status == 201){
                    this.creatingFaqs =  false
                    toast.success('Faq created successfully')
                    this.getFaqs()
                    document.getElementById(`close_faq_${id}`).click();
                }
                
            } catch (error) {
                document.getElementById(`close_faq_${id}`).click();
                this.creatingFaqs = false
                if (error.response && error.response.status === 400) { 
                    let errormsgs = '';
                    for (let x = 0; x < error.response.data.errors.length; x++) {
                        errormsgs = error.response.data.errors[x] + ','
                    }
                    toast.error(errormsgs);
                }else if(error.response && error.response.status === 401){
                  toast.error('session has expired');
                  this.$router.push({
                          name: 'login'
                      });
                } 
            }
            
        },

        onPageChange(page) {
            this.faqIds = []
            document.getElementById('check_all').checked = false;
            document.getElementById('filter_bar').style.display = 'none';
            this.page = page
            this.getFaqs();
        },
        onChangeRecordsPerPage() {
            this.getFaqs();
            // ds
        }
    },
    mixins: [ReusableFunctionMixins, ReusableDataMixins]
}
</script>
